.home {
  position: relative;
  color: #fff;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 10%;
  &__text-wrapper {
    margin-top: 10%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      color: var(--yellow-theme-main-color);
      margin: 0;
      font-weight: 500;
      font-size: 8rem;
      line-height: 10rem;
      position: relative;
      overflow: hidden;
      white-space: nowrap;

      animation: typingEffectAnimation 2.5s steps(30, end);
    }
  }

  &__contact-me {
    display: flex;
    justify-content: center;
    margin-top: 15px;

    button {
      cursor: pointer;
      padding: 10px 70px;
      font-size: 2rem;
      background: transparent;
      color: var(--yellow-theme-main-color);
      border-radius: 4px;
      border: 1.5px solid rgb(255, 255, 255, 0.5);
      font-weight: 500;
      margin: 30px 20px;

      &:hover {
        background: var(--yellow-theme-main-color);
        color: black;
        border: 1.5px solid rgb(0, 0, 0, 0.5);
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .home {
    margin-bottom: 60%;
  }
  .home__text-wrapper h1 {
    margin-top: 60%;
    font-size: 2.5rem;
    line-height: 6rem;
  }
  .home__contact-me button {
    padding: 10px 20px;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 551px) and (max-width: 768px) {
  .home {
    margin-bottom: 50%;
  }
  .home__text-wrapper h1 {
    margin-top: 50%;
    font-size: 3.5rem;
    line-height: 6rem;
  }
  .home__contact-me button {
    padding: 10px 30px;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 990px) {
  .home__text-wrapper h1 {
    font-size: 6rem;
    line-height: 8rem;
  }
}

@keyframes typingEffectAnimation {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
