.resume {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__content {
    margin-top: 10%;
    margin-bottom: 10%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    h1 {
      font-size: 4.6rem;
      justify-content: center;
      color: var(--yellow-theme-main-color);
      align-items: center;
      margin-top: 40px;
    }

    a {
      display: inline-block;
      padding: 20px 30px;

      background-color: var(--yellow-theme-main-color);
      color: var(--yellow-theme-background-color);
      text-decoration: none;
      font-weight: bold;
      font-size: 3rem;
      border-radius: 5px;
      transition: background-color 0.3s ease, transform 0.2s ease;
      box-shadow: 1px 4px 8px rgb(255, 154, 154);
      margin-top: 90px;

      &:hover {
        background-color: var(--yellow-theme-background-color);
        color: var(--yellow-theme-main-color);
        transform: translateY(-2px);
        box-shadow: 3px 8px 16px rgba(255, 255, 255, 0.87);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &::before {
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .resume {
    &__content {
      margin-top: 60%;
      margin-bottom: 60%;
      h1 {
        font-size: 2.6rem;
      }
      a {
        font-size: 2.5rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .resume {
    &__content {
      margin-top: 50%;
      margin-bottom: 50%;
      h1 {
        font-size: 2.6rem;
      }
      a {
        font-size: 2.5rem;
      }
    }
  }
}
