.App {
  height: 100%;
  scroll-behavior: smooth;
  &__main-page-content {
    height: calc(100% - 80px);
  }

  .main-page-content {
    height: 100%;
  }
}
