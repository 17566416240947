:root {
  --yellow-theme-background-color: #0e0e0e;
  --yellow-theme-main-color: #9ab0ff;
  --yellow-theme-sub-text-color: #fff;
  --yellow-theme-nav-background: #181818;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background: var(--yellow-theme-background-color);
  height: 100%;
}
#root {
  height: 100%;
}
