.about {
  &__content {
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 100%;
    overflow: hidden;

    &__personalWrapper,
    &__servicesWrapper {
      width: 50%;
    }

    &__personalWrapper {
      h3 {
        font-size: 2.5rem;
        margin-bottom: 10px;
        font-weight: 700;
        color: var(--yellow-theme-main-color);
      }

      h3.personalH3Text {
        padding-top: 20px;
      }

      p {
        font-size: 1.8rem;
        color: var(--yellow-theme-sub-text-color);
        line-height: 2.5rem;
        padding-bottom: 20px;
        border-bottom: 1px dashed var(--yellow-theme-main-color);
      }

      ul {
        list-style-type: none;
      }

      ul > li {
        line-height: 1rem;
        text-decoration: none;
        margin-bottom: 15px;
        a {
          color: var(--yellow-theme-sub-text-color);
        }
        span.title,
        span.value {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0.5px;
          display: inline-block;
          position: relative;
          margin-right: 16px;
          padding-bottom: 5px;

          color: var(--yellow-theme-sub-text-color);
        }

        span.title {
          border-bottom: 2px solid var(--yellow-theme-main-color);
        }
      }
    }

    &__servicesWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0px;

      &__innerContent {
        width: 300px;
        height: 300px;
        background: radial-gradient(
          circle,
          var(--yellow-theme-main-color) 0%,
          transparent 70%
        );
        position: relative;
        border-radius: 50%;
        border: 1.5px solid var(--yellow-theme-main-color);
        animation: rotation 10s linear 1s infinite;

        div:nth-child(1) {
          position: absolute;
          top: -9%;
          left: 41%;
        }

        div:nth-child(2) {
          position: absolute;
          right: -8%;
          top: 41.5%;
        }

        div:nth-child(3) {
          position: absolute;
          bottom: -10%;
          left: 41%;
        }

        div:nth-child(4) {
          position: absolute;
          left: -9%;
          top: 41%;
        }
        div:nth-child(5) {
          position: absolute;
          left: 4%;
          top: 5%;
        }
        div:nth-child(6) {
          position: absolute;
          bottom: 4%;
          left: 5%;
        }
        div:nth-child(7) {
          position: absolute;
          top: 75%;
          right: 5%;
        }
        div:nth-child(8) {
          position: absolute;
          right: 4%;
          top: 5%;
        }
      }
    }
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 760px) {
  .about__content {
    flex-direction: column;
    &__personalWrapper,
    &__servicesWrapper {
      width: 100%;
    }
  }
}
