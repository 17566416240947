/* src/components/Projects.css */

.projects {
  margin-bottom: 10%;
  margin-top: 10%;
  flex-direction: column;
  padding: 20px;

  height: 100%;
  display: flex;
  flex-direction: column;
}
.projects h2 {
  color: var(--yellow-theme-main-color);
}

.projects__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.projects__header h2 {
  color: var(--yellow-theme-main-color);
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
  line-height: 5rem;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  animation: typingEffectAnimation 2.5s steps(30, end);
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.project-card img {
  width: 100%;
  height: 90%;
  background-color: white;

  object-fit: contain;
  object-position: center;
}

.project-card h3 {
  margin-bottom: 10px;
  margin: 10px;
  font-size: 2em;
  color: var(--yellow-theme-main-color);
}

.project-card p {
  margin: 10px;
  color: #555;
}

.project-card:hover {
  transform: scale(1.05);
}
@media only screen and (max-width: 768px) {
  .projects__header h2 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .project-card img {
    height: 80%;
  }

  .project-card h3 {
    margin: 10px;
    font-size: 1em;
    color: var(--yellow-theme-main-color);
  }
}

@media only screen and (max-width: 550px) {
  .projects__header h2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .project-card img {
    height: 80%;
  }
  .project-card {
    width: calc(50% - 20px);
  }
  .project-card h3 {
    margin: 10px;
    font-size: 1.2em;
    color: var(--yellow-theme-main-color);
  }
}

@keyframes typingEffectAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
